<template>
  <div>
    <vx-card actionable class="cardx" title="Reporting">
      <vs-row type="flex" style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="5">
          <v-select
            label="Master"
            :options="MasterFilter"
            v-model="select_master"
            class="w-full"
          ></v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="3" v-if="this.select_master === 'Team' || this.select_master === 'Not Reporting To Anyone'">
          <!-- <vs-button
            class="rounded-r-none p-3 md:px-8 md:py-3"
            @click="cmTeam('CM')"
            :color="colorx"
            :style="cmteamselectbuttoncolor"
            size="small"
            type="border"
            >CM</vs-button
          >
          <vs-button
            class="rounded-l-none p-3 md:px-8 md:py-3"
            @click="ExEdTeam('ExEd')"
            :color="colorx"
            :style="ExEdteamselectbuttoncolor"
            size="small"
            type="border"
            >ExEd</vs-button
          > -->
          <v-select
            label="Teams"
            :options="Teams"
            v-model="selected_team"
            class="w-full"
          ></v-select>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="4" v-if="this.select_master === 'Team'|| this.select_master ==='Not Reporting To Anyone'">
          <v-select
            v-if="this.selected_team === 'CM' || this.selected_team === 'SR'"
            label="cities"
            :options="cities"
            v-model="selected_city"
            style="width: 240px; z-index: 1000"
          ></v-select>
        </vs-col>
      </vs-row>
      <vx-card actionable class="cardx" style="margin-top: 3%">
        <vs-row type="flex" style="margin-top: 2%">
          <vs-col
            vs-order="1"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
            style="font-size: 19px"
          >
          </vs-col> </vs-row
        ><br />
        <vs-table :data="citySpoc">
          <template slot="thead">
            <vs-th>Spoc Name</vs-th>
            <vs-th>Spoc Email</vs-th>
            <vs-th>City</vs-th>
            <vs-th>Team</vs-th>
            <vs-th>Reporting To</vs-th>
            <vs-th>Manger Phone Number</vs-th>
            <vs-th>Manager Email</vs-th>
            <vs-th>Action</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].first_name">{{
                data[indextr].full_name_team
              }}</vs-td>
              <vs-td :data="data[indextr].email">
                {{ data[indextr].email }}
              </vs-td>
              <vs-td :data="data[indextr].city">
                {{ data[indextr].city }}
              </vs-td>
              <vs-td :data="data[indextr].team">
                {{ data[indextr].team }}
              </vs-td>
              <vs-td :data="data[indextr].reporting_to">
                {{ data[indextr].manager_name }}
              </vs-td>
              <vs-td :data="data[indextr].phone">
                {{ data[indextr].manager_phone }}
              </vs-td>
              <vs-td :data="data[indextr].email">
                {{ data[indextr].manager_email }}
              </vs-td>
              <vs-td>
                <vs-button size="small" @click="edit(tr, indextr)" color="warning">Edit</vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </vx-card>

    <vs-popup :active.sync="reportingPopup" :title="editspoctitle">
      <div>
        <vs-row class="gap">
          <vs-col vs-w="3"></vs-col>
          <vs-col vs-w="9">
            <v-select
              style="z-index: 1000; position: fixed; width: 240px; margin-bottom: 3%"
              v-model="selectedColoumnSpoc"
              :options="selectSpocName"
              label="full_name_team"
            ></v-select>
          </vs-col> </vs-row
        ><br /><br />
        <vs-row class="gap">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
            <vs-button color="#975846" type="border" @click="postToServer()"
              >Assign</vs-button
            >
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import constants from "../../../constants.json";
export default {
  data() {
    return {
      reportingPopup: false,
      cmteamselectbuttoncolor: [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ],
      ExEdteamselectbuttoncolor: [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ],
      selected_team: "CM",
      spoc_id: "",
      colorx: "rgb(199, 42, 117)",
      sortSpoc: [],
      full_name_team: "",
      selectedColoumnSpoc: "",
      editspoctitle: "",
      cities: [],
      citySpoc: [],
      selected_city: "",
      reportingTo: "",
      team_select: this.team,
      SpocName: "",
      roles: ["IIML-PM", "IIML-HR", "IITR-BF", "IIML-BA"],
      selectSpoc: [],
      selectSpocName: [],
      cityname:null,
      Teams: [],
      edited_row_index: "",
      MasterFilter: [
        "Team",
        "Not Reporting To Anyone"
      ],
      select_master: "Team"
    };
  },
  components: {

    "v-select": vSelect,
  },
  watch: {
    select_master: function(value) {
      if(value === "Not Reporting To Anyone") {
        this.getNonReportingSPocs();
      }
      if(value === "Team") {
        if(this.selected_team !== "") {
          if (this.selected_team === "CM") {
            this.getCities(this.selected_team);
          } 
          if (this.selected_team === "ExEd") {
            this.getExEd(this.selected_team);
          }
          if(this.selected_team !== "CM" && this.selected_team !== "ExEd") {
            this.citySpoc = [];
            this.getTotalTeams("CM");
          }
        }
      }

    },
    selected_city: function (value) {
      
      this.getTotalTeams(this.selected_team);
      this.cityname =  value;
      console.log("val",  this.cityname)
      if (value !== null) {
        this.sortSpocs(value);
      } else {
        this.sortSpoc = [];
      }
    },
    selected_team: function(value) {
        this.getTotalTeams(value);

      if (value === "CM") {
        this.getCities(value);
      } 
      if (value === "ExEd") {
        this.getExEd();
      }
      if(value !== "CM" && value == "SR") {
        this.citySpoc = [];
        this.getTotalTeams(value);
      }
    },
    selectedColoumnSpoc: function (value) {
      console.log(value);
      this.reportingTo = value.id;
      console.log("this.to_spoc", this.reportingTo);
    },
  },
  mounted() {
    this.cities = [];
    this.getCities(this.selected_team);  
  },
  methods: {
    cmTeam(team) {
      console.log("team", team);
      this.cities = [];
      this.citySpoc = [];
      this.sortSpoc = [];
      this.selected_city = "";
      this.selected_team = team;
      this.getCities(team);
      this.ExEdteamselectbuttoncolor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.cmteamselectbuttoncolor = [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
    },
    ExEdTeam(team) {
      console.log("dgydg", team);
      this.cmteamselectbuttoncolor = [
        {
          background: "#ffffff",
          color: "rgb(199, 42, 117)",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.ExEdteamselectbuttoncolor = [
        {
          background: "rgb(199, 42, 117)",
          color: "#ffffff",
          border: "1px solid rgb(199, 42, 117)",
        },
      ];
      this.selected_team = team;
      this.cities = [];
      this.sortSpoc = [];
      this.citySpoc = [];
      this.selected_city = "";
      this.getExEd(team);
      console.log("team", team);
    },
    edit(selectedSpocData, row_index) {
      this.selectedColoumnSpoc = selectedSpocData.manager_name;
      this.spoc_id = selectedSpocData.id;
      this.reportingTo = selectedSpocData.reporting_to;
      this.edited_row_index = row_index;
      console.log("selectedSpocData", selectedSpocData);
      this.editspoctitle = selectedSpocData.first_name + ",  " + selectedSpocData.city;
      //   this.editNetEnquiriecount = selectedSpocData.net_enquiries_sorted.length
      this.reportingPopup = true;
    },
    getCities(team) {
      this.$vs.loading();
      this.citySpoc = [];
      let url = `${constants.SERVER_API}getAllUsers`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("den", response.data.data);
          // this.selectSpoc = response.data.data.filter((x) => x.dummy !== 1);
          // this.selectSpoc.forEach((spoc) => {
          //   spoc.full_name_team = `${spoc.full_name} ${spoc.user_tag ? "-" : ""} ${
          //     spoc.user_tag ? spoc.user_tag : ""
          //   }`;
          // });
          this.selectSpocName = response.data.data.filter((x) => x.dummy !== 1);
          console.log("this.selectSpocName",this.selectSpocName)
          this.selectSpocName.forEach((spoc) => {
            spoc.full_name_team = `${spoc.full_name} ${spoc.user_tag ? "-" : ""} ${
              spoc.user_tag ? spoc.user_tag : ""
            }`;
          });
          this.sortSpoc = response.data.data.filter(
            (x) => x.dummy !== 1 && x.team === "CM"
          );
          // this.Teams.push(this.sortSpoc.team);
          // this.sortSpoc = response.data.data.filter((x) => x.dummy !== 1);
          console.log("hey", this.sortSpoc);
          if(this.select_master === "Team") {
            console.log("Testing", team)
            var unique = response.data.data
              .map((name) => {
                return {
                  count: 1,
                  name: name.team,
                };
              })
              .reduce((a, b) => {
                a[b.name] = (a[b.name] || 0) + b.count;
                return a;
              }, {});
            // console.log("gsdyuas", unique);
            this.Teams = [];
            for (var key in unique) {
              if (unique.hasOwnProperty(key)) {
                // console.log("key", key)
                if (key != "null") {
                  this.Teams.push(key);
                }
              }
            }
            this.Teams = this.sortArrayAlphabetically(this.Teams);
            this.extractSpocByCity(response.data.data, team);
            this.sortSpocs(this.selected_city);
          }else {
            // console.log(" Un Testing")
            this.extractSpocByCity(response.data.data, team);
          }
          
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getTotalTeams(team) {
      this.$vs.loading();
      this.citySpoc = [];
      let obj = { }
            //axios.get(url, { params: obj, headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }, })
            if(team === 'CM'||team === 'SR'){
                obj = {
                "team_name":team,
                "city_name":this.selected_city
               }
            }
            else{
               obj = {
                "team_name":team,
               }
            }
   
      let url = `${constants.SERVER_API}getAllUsers`;
      axios
        .get(url, {params: obj,
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
           console.log("finalDAta", response.data.data);
          this.selectSpoc = response.data.data.filter((x) => x.dummy !== 1);
          console.log("this.selectSpoc",this.selectSpoc)
          this.selectSpoc.forEach((spoc) => {
            spoc.full_name_team = `${spoc.full_name} ${spoc.user_tag ? "-" : ""} ${
              spoc.user_tag ? spoc.user_tag : ""
            }`;
          });
          this.sortSpoc = response.data.data.filter(
            (x) => x.dummy !== 1 && x.team === team
          );
          // this.selectSpoc = response.data.data.filter((x) => x.dummy !== 1);
          // console.log("hey", this.sortSpoc);

          // this.extractSpocByAllTeams(response.data.data, team);
          if(this.select_master === "Team") {
            this.citySpoc = this.sortSpoc;
          }else {
            // console.log(" Un Testing")
            this.citySpoc = this.sortSpoc;
          }
          
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getNonReportingSPocs() {
      this.$vs.loading();
      this.citySpoc = [];
      let url = `${constants.SERVER_API}getAllUsers`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log("den", response.data.data);
          this.selectSpoc = response.data.data.filter((x) => x.dummy !== 1);
          this.selectSpoc.forEach((spoc) => {
            spoc.full_name_team = `${spoc.full_name} ${spoc.user_tag ? "-" : ""} ${
              spoc.user_tag ? spoc.user_tag : ""
            }`;
          });
          this.sortSpoc = response.data.data.filter(
            (x) => x.dummy === 0 && x.active === true && x.reporting_to === 0);
          // console.log("hey", this.sortSpoc);

          // this.extractSpocByAllTeams(response.data.data, team);
          
          this.citySpoc = this.sortSpoc;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getExEd(team) {
      this.$vs.loading();
      this.citySpoc = [];
      let url = `${constants.SERVER_API}getAllUsers`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.sortSpoc = response.data.data.filter(
            (x) => x.dummy !== 1 && x.team === "ExEd"
          );
          // console.log(this.sortSpoc);
          this.selectSpoc = response.data.data.filter((x) => x.dummy !== 1);
          this.selectSpoc.forEach((spoc) => {
            spoc.full_name_team = `${spoc.full_name} ${spoc.user_tag ? "-" : ""} ${
              spoc.user_tag ? spoc.user_tag : ""
            }`;
          });
          if(this.select_master === "Team") {
            // console.log("Testing")
            this.extractSpocBySubTeam(response.data.data, team);
            this.sortSpocs(this.selected_city);
          }else {
            // console.log(" Un Testing")
            this.extractSpocBySubTeam(response.data.data, team);
          }
          // this.extractSpocBySubTeam(response.data.data, team);
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    postToServer() {
      let url = `${constants.SERVER_API}assignReportingManager`;
      let obj = {
        spoc_id: this.spoc_id,
        reporting_to: this.reportingTo,
      };
      console.log(obj);
      axios
        .post(url, obj, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log(response);
          // this.handleNotification(response);
          // this.spoc_id = obj.spoc_id;
          // this.reportingTo = obj.reporting_to;
          // this.getCities(this.selected_team);
          // this.getExEd();
          // this.cmTeam();
          this.reportingPopup = false;
          this.citySpoc[this.edited_row_index].manager_email = response.data.manager_email;
          // this.sortSpocs(this.selected_city);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    extractSpocByAllTeams(response, team) {
      // console.log("response",team)
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.team,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});

      this.cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          if (key === team) {
            this.cities.push(key);
          }
        }
      }
      // console.log("gsdyuas", this.cities);
      this.cities = this.sortArrayAlphabetically(this.cities);
      this.selected_city = this.roles[0];
    },
    extractSpocBySubTeam(response) {
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.sub_team,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});

      this.cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          console.log("key", key)
          if (
            key != "CM" &&
            key != "EditMobile" &&
            key != "GM" &&
            key != "INT" &&
            key != "null" &&
            key != "Corporate Relations" &&
            key != "Warehouse" &&
            key != "Alumni SR" &&
            key != "SR" &&
            key != "Sales Channel" &&
            key != "Collection" &&
            key != "HR"
          ) {
            this.cities.push(key);
          }
        }
      }
      // console.log("gsdyuas", this.cities);
      this.cities = this.sortArrayAlphabetically(this.cities);
      this.selected_city = this.roles[0];
    },
    extractSpocByCity(response) {
      var unique = response
        .map((name) => {
          return {
            count: 1,
            name: name.city,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      // console.log("gsdyuas", unique);
      this.cities = [];
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          // console.log("key", key)
          if (key != "null") {
            this.cities.push(key);
          }
        }
      }

      this.cities = this.sortArrayAlphabetically(this.cities);
      if (localStorage.getItem("role") === "cm4") {
        this.selected_city = this.cities[0];

        // this.selected_city = this.roles[0];
      } else if (localStorage.getItem("role") === "cm1") {
        this.SpocName.forEach((spoc) => {
          if (spoc.id === this.loggedin) {
            this.selected_city = spoc.city;
          }
        });
      }
    },
    sortSpocs(cityName) {
      // console.log(cityName);
      this.citySpoc = [];
      this.sortSpoc.forEach((spoc) => {
        if (spoc.city === cityName) {
          this.citySpoc.push(spoc);
          // console.log("spoc team", this.citySpoc);
        } else if (spoc.sub_team === cityName) {
          this.citySpoc.push(spoc);
        }
      });
    },
  },
};
//Testing
</script>
