<template>
  <div>
      <reporting/>
  </div>
</template>

<script>
import reporting from "../../src/components/Report/reporting.vue"
export default {
    components:{
        reporting
    }

}
</script>

<style>

</style>